import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TalkTemplate = ({ data }) => {
  const { Title, Slide, Slug, Description, Video } = data.strapiTalks
  return (
    <Layout>
      <SEO title={Title} />
      <section
        className="resume-section p-3 p-lg-5 d-flex flex-column justify-content-top"
        id={Slug}
      >
        <div className="w-100">
          <h1 className="mb-5">{Title}</h1>
          <div className="lead mb-5">
            <ReactMarkdown source={Description} />
          </div>
          {Slide && (<p>
            Slide: <a href={Slide}>{Slide}</a>
          </p>)}
          {Video && (<p>
            Video: <a href={Video}>{Video}</a>
          </p>)}
        </div>
      </section>
    </Layout>
  )
}

export default TalkTemplate

export const pageQuery = graphql`
  query TalkTemplateQuery($slug: String!) {
    strapiTalks(Slug: { eq: $slug }) {
      id
      Description
      Title
      Slug
      Slide
      Video
      Presented {
        id
        Date
        event {
          Link
          Location
          Name
          Slug
          Type
          id
        }
      }
    }
  }
`
